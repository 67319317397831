<script>
  import { required, email } from "vuelidate/lib/validators";
  import appConfig from "@/app.config";
import router from "../../../router";
  /**
   * Login component
   */
  export default {
    page: {
      title: "Token",
      meta: [
        {
          name: "description",
          content: appConfig.description,
        },
      ],
    },
    mounted() {
    
    this.getToken();
  },
   methods: 
    {
      getToken()
      {
        let self = this;
        let verificationToken=this.$route.query.token;//get the verification token from the route params
        this.$axios.post('/verify-token',{ 
          verifyToken: verificationToken
           })
          .then(response => {
            const user = response.data.payload.user;
            self.$store.commit('auth/SET_CURRENT_USER', user);
            // toaster messages
            if(response.data.status !== true){
              self.triggerSwal(response.data.message, "error")
                }
            else{
                self.triggerSwal(response.data.message, "success");
                }
            // redirect to the dashboard
             window.location.href="/";
           
          }).catch(error => {
            self.handleErrorResponse(error.response, "error");
                        
          });
      },
    }
 };
  </script>
  
  <template>
    <div>
      <div  class="spinLoader flex-column">
        <div>
          <!-- <h4 class="mb-3"> {{errorMessage}}</h4> -->
        </div>
       
        <div class="spinner-border text-danger" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>

    </div>
  </template>
  
  <style lang="scss" module></style>
  